/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// global.scss
@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';
@import '~@angular/cdk/overlay-prebuilt.css';

// ion-header{
//     z-index: 0 !important;
// }

ion-title .toolbar-title {
    display: block;
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    pointer-events: auto;
 }

.ion-page{
    background: url(assets/back_img.jpg);
    background-size: cover;
    width: 100%;
    background-repeat: no-repeat;
    height: 100%;
  }
  ion-content {
    --background: var(--ion-background-color, transparent);
  }

ion-toolbar ion-title{
    font-size: 18px;
    padding-left: 0px !important;
    padding-right: 15px !important;
    color: #3D3939;
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: 600;
}

.search_results{
    strong{
      padding-left: 16px;
    }
  }

// ion-header{
// ion-toolbar{
//   --background:#3971b6;
// }
// }
// h1, h2, h3, h4, h5, h6{
//     font-family:var(--header-font-family);
// }
// * {
//     font-family:var(--text-font-family);
// }
// span{
//     font-family:var(--text-font-family);
// }

.badge_notify {
  position: absolute;
  top: 5px;
  right: 8px;
  font-size: 10px;
  border-radius: 50%;
  background: red;
  color: #fff;
  font-weight: 600;
  height: 19px;
  width: 18px;
  padding: 5px 3px;
}


* { font-family: var(--font); }


.form_fields{
    margin-top: 10px;
}
.dash_log{
    display: flex;
    align-items: center;
}
.rating_icon{
    display: flex;
    background: #E8F3F1;
    border-radius: 3px;
    padding: 3px 4px;
    align-items: center;
}
.login_accts{
    float: right;
}
.accept_btns{
    height: 55px;
    width: 100%;
    --background: var(--ion-color-blue);
    color: var(--ion-color-primary-contrast);
    --border-radius: 5px;
    font-size: 18px;
    margin-bottom: 20px;
}
.error_msg{
    margin-left: 0px;
}
.searchbar-clear-icon.sc-ion-searchbar-md {
    // background-color: #F5F5F5 !important;
    width: 22px;
    height: 100%;
}
    ion-menu {
    --width: 100%;
}

.spn-txt{
    color:var(--ion-color-danger);
}
.avatar-preview{
    width: 180px;
    height: 180px;
    position: relative;
    border-radius: 50px;
    overflow: visible;
    // border: 1px solid #fff;
    margin: 0 auto;
    display: block;
    .imagePreview{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .header-md::after{
    display: none;
  }
.dash_log ion-title{
    font-size: 18px;
    float: left;
    padding-left: 0px;
    margin-right: 90px !important;
    white-space: nowrap;
    width: 80px;
    color: #3D3939 !important;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: 600;
    line-height: 37px !important;
}
ion-back-button, ion-menu-button{
  color: #000 !important;
}
.skip-text{
    position: absolute;
    top: 20px;
    right: 20px;
    text-decoration: none;
    font-weight: 500;
    color: #000 !important;
}
.login_signup_buttons{
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: center;
    margin-top: 22px;
    margin-left: 20px;
    margin-right: 20px;
}
.signup-text {
    // background-color: var(--ion-color-dark-blue);
    color: var(--ion-color-light-black-gray) !important;
    padding: 10px 10px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;

}
.email-text {
    // background-color: var(--ion-color-dark-blue);
    color: var(--ion-color-blue);
    text-decoration: none;
    font-weight: 500;
    margin-top: 5px;
}

:host(.show-menu) {
    display: block;
    --background: var(--ion-background-color, #ffffff59) !important;
  }
  :host {
    --background: none !important; // or whatever color you want
}

    app-otp-verification {
        ion-content { position: absolute;}
    }
ion-card-header{
    h4{
        font-size: 30px;
        font-weight: 100 !important;
        margin: 0px;
        span{
            font-weight: 600;
        }
    }
}
ion-searchbar{
    .searchbar-input{
        background: #e5f7ff !important;
        border: 1px solid #cccccc !important;
        border-radius: 5px !important;
        box-shadow: none !important;
        padding-inline-start: 30px !important;
        padding-inline-end: 10px !important;
        padding-top: 11px !important;
        padding-bottom: 11px !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 14px !important;
        width: 100% !important;
    }
    .searchbar-search-icon.sc-ion-searchbar-md{
        left: 7px !important;
        top: 10px;
        width: 20px;
        height: 20px;
        color:var(--ion-color-secondary);
    }
}

.search {
    margin-top: 40px;
    display: flex;
    ion-input {
      background: var( --ion-color-light-tint);
      border-radius: 10px;
      height: 45px;
      --padding-start: 16px;
      font-size: 12px;
    }

    ion-button {
      height: 45px;
      width: 50px;
      --border-radius: 30px 10px 10px 10px;
      order: 1;
    }
  }
  .sc-ion-searchbar-md-h{
    // padding: 5px 15px 10px !important;
    padding: 0px 16px !important;
    background: #fff;
    // margin-bottom: 10px;
    // margin-top: 10px;
  }
  .doc_spelst{
    // display:flex;
    // align-items: center;
    // justify-content: space-between;
    h5{
        // font-weight: 400;
        // font-size: 25px;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        margin: 0px !important;
        margin-bottom: 5px;
        color: #101623;
    }
    h6{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0px;
        background: #3971B6;
        border-radius: 50px;
        color: var(--ion-color-primary-contrast);
        padding: 5px 15px;
    }
    // h6:hover{
    //     color: var(--ion-color-danger);
    // }

  }
  ion-slides{
    ion-slide{
        .card-categories{
            position: relative;
            img{
                margin-bottom: 11px;
            }
            p{
                font-size: 12px;
                font-weight: 500;
                line-height: 14px;
                margin:0px !important;
            }
            span{
                font-size: 21px;
                font-weight: 600;
                line-height:25.6px;
            }
        }
    }
  }
  .top_dctrs{
    display:flex;
    align-items: center;
    justify-content: space-between;
    h5{
        // font-weight: 400;
        // font-size: 25px;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        margin: 0px !important;
        color: #101623;
    }
    h6{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin: 0px;
        background: #3971B6;
        border-radius: 50px;
        color: var(--ion-color-primary-contrast);
        padding: 5px 15px;
    }
    // h6:hover{
    //     color: var(--ion-color-danger);
    // }
  }
  ion-slides{
  .patient-web-app__sc-1iqyrb6-1{
    min-height: 220px;
    background: #FFFFFF;
    border: 2px solid #E8F3F1;
    border-radius: 12px;
    padding: 15px 0px 0px;
        .doctor_details{
            padding-top: 10px;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 5px;
            div{
                white-space: nowrap;
                width: 115px;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0 auto;
                display: block;
                span{
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 18px;
                    text-align: center;
                    color: #3b4453;
                }
            }

        small{
          font-weight: 500;
          font-size: 12px;
          line-height: 11px;
          text-align: center;
          margin-bottom: 0px;
          color: #575757;
          float: unset !important;
        }
        }
    }
    }
    .health_kit{
        ion-row{
            display:flex;
            align-items: center;
            justify-content: space-between;

            h5{
                font-weight: 400;
                font-size: 25px;
                line-height: 32px;
                margin-bottom: 15px !important;
            }
            a{
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #5B93FF;
                text-decoration: none;
            }
      }
    }
    .recomm_setns{
        ion-row{
            display:flex;
            align-items: center;
            justify-content: space-between;

            h5{
                // font-weight: 400;
                // font-size: 25px;
                font-weight: 600;
                font-size: 18px;
                line-height: 32px;
                margin-bottom: 0px !important;
                margin-top: 0px;
                color: #101623;
            }
            h6 {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                margin: 0px;
                background: #3971B6;
                border-radius: 50px;
                color: var(--ion-color-primary-contrast);
                padding: 5px 15px;
            }
            // h6:hover {
            //     color: var(--ion-color-danger);
            // }

            a{
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #5B93FF;
                text-decoration: none;
            }
        }
    }
    ion-grid{
        span{
            p{
                font-weight: 600;
                font-size: 13px;
                line-height: 17px;
                text-align: center;
                letter-spacing: 0.001em;
                color: var(--ion-color-black_light);
                margin: 0px auto;
                padding-top: 5px;
                white-space: nowrap;
                width: 90px;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 10px;
            }
        }
        .card-categories1{
            // border-radius: 10px;
            img{
                // margin: 0 auto;
                // display: block;
                // height: 100%;
                // width: 100%;
                // max-width: 60px;
                // max-height: 60px;
            }
            .circle_bg{
                position: absolute;
                top: 5px;
                left: 5px;
                border-top-left-radius: 10px;
                width: 25px;
                height: 25px;
                object-fit: cover;
            }
        }
    }
    app-home{
        ion-content{
            .skip_content{
                position: absolute;
                top: 15px;
                right: 19px;
                color: #fff;
                font-size: 15px;
                text-decoration: none;
            }
        }
        .swiper-slide{
            ion-button{
                width: 90%;
                // padding: 15px;
                border-radius: 10px;
                --background: #3971B6;
                color: #fff;
                height: 45px;
                font-size: 18px;
                line-height: 23px;
                font-weight: 700;
                position: absolute;
                bottom: 53px;
                left: 17px;
            }
            .slider_content{
                margin-top: 160px;
                margin-bottom: 41px;
                h3{
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 38px;
                    text-align: center;
                    color: #FFFFFF;
                }
                p{
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: #FFFFFF;
                    width: 60%;
                    margin: 0 auto;
                    display: block;
                }
            }
        }
        .initial_image{
            position: relative;
            img{
            position: absolute;
            top: 60%;
            left:50%;
            transform: translate(-50%,-60%);
            z-index: 9;
            }
        }
    }



    app-appointment, app-wishlist{

        .view_txts{
            display: flex;
            align-items: center;
        }
        .ion-color-danger{width: 15px;
            height: 15px;}
        .appoint_dctrs, ion-item{
            margin: 5px 15px 15px 15px;
            background: #FFFFFF !important;
            box-shadow: 0px 0px 16px #dbdbdb;
            border-radius: 10px;
            border: 1px solid #dbdbdb;
            padding: 0px 5px;
            .item-native{
                background-color: var(--ion-color-danger-contrast);
                padding: 10px 10px 0px 10px;
                .item-inner{
                    border-bottom: 0px !important;
                }
            }
            ion-thumbnail{
                width: 110px;
                height: 110px;
                margin: 0 auto;
                display: block;
                --border-radius: 50% !important;
                object-fit: cover;
            }
            ion-label{
                margin-bottom:0px;
                padding-left:12px;
                .icons_lists{
                    ion-row{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
            a{
                font-size: 12px;
                text-decoration: none;
                color: var(--ion-color-secondary);
                font-weight: 600;
            }
        }
        .timing_appt{
            margin-left:5px;
            ion-chip{
                background: var(--ion-color-danger-tint);
                color: var(--ion-color-secondary-contrast);
                font-size: 10px;
                padding: 8px 11px;
                border-radius: 6px !important;
                height: 27px !important;
            }

        }
        .wish_list {
          padding-right: 10px;
          width: 23px;
          height: 23px;
          float: right;
          position: absolute;
          right: 0px;
          top: 5px;
          color: #196c9a !important;

            // :last-child{
            //     width: 15px;
            //     height: 15px;
            // }
        }
    }
    app-view-doctor{
        padding: 0px 0px;
        .videocam_icon{
            position: absolute;
            top: 80%;
            right: 22%;
            background-color: var(--ion-color-danger-tint);
            border-radius: 50%;
            font-size: 20px;
            height: 40px;
            width: 40px;
            transform: translateY(-80%);
            img{
                margin: 11px auto;
                display: block;
            }
        }
        ion-content{
            --padding-start: 10px;
            --padding-end: 10px;
            .avatar{
                padding-top: 15px;
                img{
                    background: #D9D9D9;
                    border-radius: 50%;
                    width: 190px;
                    height: 190px;
                    margin: 0 auto;
                    display: block;
                    object-fit: cover;
                }
            }
            .card-body{
                padding: 0px 0px 15px 0px !important;
                h2{
                    font-size: 18px;
                    font-weight: 600;
                    color: #303940;
                    line-height: 19px;
                    margin-top: 9px !important;
                    margin-bottom: 0px !important;
                    width: 100%;
                    margin-top: 0px !important;
                }
                h6{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0px 30px;
                    .network_ranking{
                        display: flex;
                        align-items: center;
                        p{
                            font-size: 18px;
                            color: rgba(0, 0, 0, 0.5);
                            font-weight: 600;
                        }
                    }
                }
                .working_hours{
                    padding-top: 0px;
                    padding-bottom: 0px;
                }
                .patient_review{
                    background: rgba(217, 217, 217, 0.2);
                    padding:10px 15px 15px;
                    margin-top: -7px;
                    h3{
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 32px;
                        color: #3D3939;
                        margin-top: 5px !important;
                        margin-bottom: 0px !important;
                    }
                    h4{
                        font-weight: 300;
                        font-size: 23px;
                        line-height: 32px;
                        color: #3D3939;
                        margin: 0px;
                    }
                    .col-md-6{
                        // width:50%;
                        // float:left;
                        // margin-bottom: 10px;
                        .waiting_time{
                            margin-bottom: 25px;
                        }
                        h1{
                            margin: 0px;
                            font-size: 87px;
                            color: #3D3939;
                            line-height: 83px;
                            font-weight: 300;
                        }
                        h5{
                            font-weight: 300;
                            font-size: 21px;
                            line-height: 26px;
                            margin: 0px;
                        }
                        .wait_timing, .bed_side_mners{
                            display: flex;
                            align-items: center;
                        }
                    }
                    .description{
                        clear: both;
                        p{
                            background-color: #eee;
                            padding: 20px;
                            border-radius: 10px;
                            line-height: 18px;
                            font-size: 13px;
                            color: #3D3939;
                            text-align: left;
                        }
                    }
                    .working_time{
                        h3{
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 17px;
                            color: var(--ion-color-warning-contrast);
                            padding-bottom: 6px !important;
                        }
                        p{
                            margin-top: 0px !important;
                            font-size: 14px;
                        }
                        .date_time{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }
                }
            }
        }

            .location_view{
                ion-icon{
                    font-size: 25px;
                    margin-right: 10px;
                }
                p{
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .ion-accordion-toggle-icon{
                display: none;
            }
            .accordion-collapsed ion-item, .accordion-collapsing ion-item{
                --background: var(--ion-color-step-950);
                padding: 5px 0px;
                --border-radius: 15px !important;
                color: var(--ion-color-black_dark);
                ion-label{
                    padding: 0px 25px;
                    font-size: 20px;
                    font-weight: 600;
                }
            }
            .accordion-expanded ion-item, .accordion-collapsing ion-item, .accordion-expanded ion-item, .accordion-expanding ion-item{
                --background: #007aff;
                padding: 5px 0px;
                --border-radius: 15px !important;
                color: var(--ion-color-primary-contrast);
                ion-label{
                    padding: 0px 25px;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
            .accordion-expanding ion-item, .accordion-collapsing ion-item{
                --background:#007aff;
                padding: 5px 0px;
                --border-radius: 15px !important;
                color: var(--ion-color-primary-contrast);
                ion-label{
                    padding: 0px 25px;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
            .accrd_detail{
                h3{
                    font-size: 16px;
                    margin-top: 0px;
                }
                p{
                    margin-top: 0px;
                    font-size: 14px;
                }
                h2{
                    margin-top: 0px;
                    font-size: 16px !important;
                }
            }
            .working_hours{
                th{
                    text-align: left;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 15px;
                    // border-bottom: 1px solid #eee;
                }
                td{
                    text-align: left;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 15px;
                    // border-bottom: 1px solid #eee;
                }
            }
            .reviews_tab{
                padding: 10px 5px;
                ion-card{
                    background: #FFFFFF;
                    box-shadow: 0px 0px 20px rgb(0 0 0 / 8%);
                    border-radius: 8px;
                    padding: 12px 15px;
                    margin: 0px 5px;
                }
                .col-md-3{
                    width:30%;
                    float:left;
                }
                .col-md-9{
                    width:70%;
                    float:left;
                }
                .review_data{
                    h3{
                        color: var(--ion-color-warning-contrast);
                        font-size: 13px;
                    }
                    h5{
                        color: var(--ion-color-secondary);
                        font-size: 15px;
                    }
                    p{

                        color: var(--ion-color-warning-contrast);
                        font-size: 14px;
                        font-weight: 500;

                    }
                }
                button{
                    width: 100%;
                    padding: 10px 10px;
                    margin-left: 0px;
                    font-size: 13px;
                    line-height: 19px;
                    display: block;
                    font-weight: 700;
                    border-radius: 5px;
                    background: var(--ion-color-dark-blue) !important;
                    color: var(--ion-color-primary-contrast);
                    margin-bottom: 25px;
                }
            }

    }
    app-appointment-booking{
        ion-content{
            h3{
                margin: 0px;
            }
            ion-grid{
                display: flex;
                align-items: center;
                justify-content: space-around;

                ion-badge{
                    // padding: 15px 7px;
                    // border-radius: 20px;
                    // width: 40px;
                    padding: 0px;
                    // background-color: var(--ion-color-blue);
                    .week{
                        padding-bottom: 3px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        letter-spacing: 1px;
                    }
                    .day{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 1px;
                    }
                }
                .week_date{
                    width: 110%;
                    background-color: var(--ion-color-primary-contrast);
                    border-radius: 10px !important;
                    .week{
                        // color:var(--ion-color-light-gray);
                        color: #000c;
                        font-size: 14px;
                        line-height: 17px;
                        font-weight: 600 !important;
                    }
                }
            }
            .days_shift{
                display: block !important;
            }
            button {
                background: var(--ion-color-blue) !important;
                color: #fff !important;
                width: 90%;
                border-radius: 8px;
                margin: 13px auto;
                display: block;
                font-size: 15px;
                font-weight: 600;
                padding: 15px;
            }
        }

        // radio css
        label {
            display: inline-block;
            cursor: pointer;
            border-radius: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0px;
            padding: 8px 3px;
            border: 0px solid #DCDCDC;
            color: #736D6D;
            background: #fff;
            box-shadow: 0px 6px 21px 3px #eee;
            width: 100%;
          }

          input:checked + label {
            background-color: var(--ion-color-secondary);
            color: var(--ion-color-primary-contrast);
            border: 0px;
          }

          input {
            width: 0;
            height: 0;
            // visibility: hidden;
            position: absolute;
          }

          .answer-radio {
            position: relative;
          }


          label::before {
            width: 16px;
            height: 16px;
            border: 1px solid #ddd;
          }

          label::after {
            width: 10px;
            height: 10px;
            background-color: #ddd;
            left: 9px;
            top: 14px;
            display: none;
          }

          input:checked + label::after {
            display: inline-block;
          }
          .day_date .week{
            height: 70px;
            background-color: transparent;
            border: none;
            color: #fff;
            padding: 20px 0px;
            width: 40px;
            font-size: 13px;
            font-weight: 400;
          }
          .day_date{
            --background: var(--ion-color-secondary-light) !important;
            padding: 0px;
            border-radius: 18px;
          }
    }
    app-login, app-email-login{
        ion-content{
            h1{
                font-size: 30px;
                line-height: 38px;
                font-weight: 600;
                margin-top: 60px;
                text-align: center;
            }
            img{
                // margin: 60px auto 40px;
                // display: block;
            }
            form{
                padding: 0 20px;
                ion-label{
                    float: left;
                    width: 100%;
                    text-align: left;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                }
                ion-item{
                    width: 100%;
                    // border: 2px solid rgba(27, 27, 27, 0.15);
                    // float: left;
                    // border-radius: 8px;
                    // background-color: #fff !important;
                    width: 100%;
                    margin-top: 0px;
                    margin-bottom: 22px;
                    outline: none;
                    height: 48px;
                    --background: #F6F6F6!important;
                    box-shadow: inset 2px 2px 2px 4px rgb(0 0 0 / 12%);
                    border-radius: 8px;
                    .item-highlight{
                        border-bottom: 0px solid #fff !important;
                    }
                }
                .submit{
                    width:100%;
                    margin: 0 auto;
                    display: block;
                    --background: var(--ion-color-dark-blue) !important;
                    color: var(--ion-color-primary-contrast) !important;
                    // padding: 15px 10px;
                    border-radius: 8px;
                    font-weight: 600;
                    font-size: 16px;
                }
                .hide-option, .show-option{
                    padding-top: 5px;
                    color: #000;
                    width: 24px;
                    font-size: 18px;
                }
            }
        }
    }
    app-otp-verification{
        ion-content{
            h1{
                font-size: 30px;
                line-height: 38px;
                font-weight: 600;
                margin-top: 60px;
            }
            img{
                // margin: 40px auto 20px;
                display: block;
            }
            p{
                margin-top:25px;
                font-weight: 500;
                font-size: 15px;
                line-height: 19px;
                margin: 0px 20px;
            }
            small{
                font-size: 13px;
                line-height: 19px;
                text-align: center;
                margin-top: 21px;
            }
            form{
                padding: 38px 20px 0px;
                input{
                    width: 55px !important;
                    height: 55px !important;
                    // margin-right: 10px !important;
                    border: 0.5px solid var(--ion-color-dark-blue) !important;
                    // border-radius: 8px !important;
                    color: var(--ion-color-secondary);
                    outline: none;
                    margin-bottom: 15px;
                    background: #F6F6F6;
                    box-shadow: inset 2px 2px 4px rgb(0 0 0 / 12%);
                    border-radius: 10px;
                    border: 0px !important;
                    color: #000000;
                }
                .submit{
                    width:100%;
                    margin: 5px auto 5px;
                    display: block;
                    --background: var(--ion-color-dark-blue) !important;
                    color: var(--ion-color-primary-contrast) !important;
                    // padding: 15px 10px;
                    border-radius: 10px;
                    font-weight: 600;
                    font-size: 17px;
                    --background: var(--ion-color-dark-blue)
                }

            }
        }
    }
    app-registration{
        ion-content{
                h1{
                    font-size: 30px;
                    line-height: 38px;
                    font-weight: 600;
                    margin-top: 10px;
                    padding-left: 30px;
                }
                img{
                    margin: 10px auto 10px;
                    display: block;
                }
            form{
                padding: 15px 30px;
                ion-label{
                    float: left;
                    width: 100%;
                    text-align: left;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                }
                ion-item{
                    width: 100%;
                    border: 2px solid rgba(27, 27, 27, 0.15);
                    float: left;
                    border-radius: 8px;
                    background-color: #fff !important;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    outline: none;
                    height: 50px;
                }
                .submit{
                    width:100%;
                    margin: 0px auto;
                    display: block;
                    --background: var(--ion-color-blue) !important;
                    color: var(--ion-color-primary-contrast) !important;
                    // padding: 20px 10px;
                    border-radius: 10px;
                    font-weight: 600;
                    font-size: 15px;
                    height: 38px;
                }
                .terms_condts{
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 10px;
                    ion-label{
                        padding-left: 15px;
                    }
                }
                .hide-option, .show-option{
                    padding-top: 5px;
                    color: #000;
                    width: 24px;
                    font-size: 18px;
                }
            }
        }
    }
    app-profile{
        ion-content{
            .user{
                ion-row{
                    align-items: center;
                }
                margin-left: 0px;
                margin-right: 0px;
                // border-bottom: 1px dashed rgba(27, 27, 27, 0.15);
            }
            // .sub_user{
            //     border-bottom: 1px dashed rgba(27, 27, 27, 0.15);
            // }
            .form_fields{
                margin-left: 15px;
                margin-right: 15px;
            }
            h2{
                font-weight: 500 !important;
                font-size: 20px !important;
                line-height: 30px;
                color: #FFFFFF;
                letter-spacing: 0.8px;
            }
            .mobile_num{
                font-weight: 400;
                font-size: 12px;
                line-height: 11px;
                color: #FFFFFF;
                letter-spacing: 0.8px;
            }
            // .email_txt{
            //     font-weight: 600;
            //     font-size: 9px;
            //     line-height: 11px;
            //     color: #FFFFFF;
            // }
            ion-item::part(native) {
                //WRITE ALL YOUR CSS RULES HERE
                padding-left: 15px;
                /** and lots more **/
              }
            .input_field{
                width: 100%;
                border: 2px solid rgba(27, 27, 27, 0.15);
                float: left;
                border-radius: 8px;
                background-color: #fff !important;
                margin-top: 10px;
                margin-bottom: 13px;
                outline: none;
                padding-left: 10px;
                    .item-inner{
                        border-bottom: 0px !important;
                    }
                    .left_icon{
                        background-color: var(--ion-color-dark-gray);
                        padding: 5px;
                        font-size: 20px;
                        border-radius: 5px;
                        color: #1B1B1B;
                        margin-top: 17px;
                        margin-bottom: 17px;
                    }
            }
            ion-list{
                margin-left: 15px !important;
                margin-right: 15px !important;
            }
            form{
                padding: 15px 30px;
                ion-label{
                    float: left;
                    width: 100%;
                    text-align: left;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: var(rgba(27, 27, 27, 0.5));
                }
            }
            .state_city{
                width: 100%;
                display: flex;
                .col-md-6{
                    float: left;
                    width: 50%;
                }
            }
            .subprofile_users{
                margin-top: 0px !important;
                text-align: center !important;
                width: 100%;
                ion-label{
                    visibility: hidden;
                }
                ion-img{
                    width: 50px;
                    height: 50px;
                    border-radius: 0px;
                    object-fit: cover;
                    margin: 0 auto;
                    display: block;
                }

            }
            .subprofile_users:hover ion-label{
                visibility: visible;
                text-align: center;
                text-overflow: initial;

            }
        }
        .chng_pwds{
            a{
                float: right;
                font-size: 16px;
                color: #000;
                font-weight: 400;
            }
        }
    }
    app-confirm-booking{
        .list-doctors{
            background: #FFFFFF;
            box-shadow: 0px 0px 20px rgb(0 0 0 / 8%);
            border-radius: 8px;
            ion-item{
                // border: 1.5px solid var(--ion-color-blue);
                border-radius: 20px;
                margin-top: 12px;
                margin-bottom: 10px;
                img{
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                    margin: 0 auto;
                    display: block;
                }
                p{
                    font-size: 11px;
                    color: var(--ion-color-light-contrast);
                    font-weight: 500;
                    span{
                        color: var(--ion-color-primary-tint);
                    }
                }
                ion-icon{
                    background-color: var(--ion-color-danger);
                    color: var(--ion-color-primary-contrast);
                    border-radius: 50%;
                    padding: 5px;
                }
            }
            button{
                padding: 15px;
                margin: 0 auto 10px;
                display: block;
                font-size: 16px;
                font-weight: 500;
                width: 100%;
                border-radius: 10px;
                color: #000;
                background-color: var(--ion-color-dark-gray);
            }
            .remove_inner_bottom{
                --inner-border-width: 0;
            }
            h3{
                font-size: 17px;
                font-weight: 600;
            }
        }
        ion-list{
            ion-item{
                // border-top: 1px dashed #ccc;
                padding-bottom: 10px;
                p{
                    color: var(--ion-color-light-contrast) !important;
                }
            }
            .pur_items{
                width: 100%;
                display: block;
                margin-top: 10px;
                ion-input{
                    --background: var(--ion-color-secondary-contrast);
                    border: 2px solid var(--ion-color-dark-gray);
                    border-radius: 8px;
                    }
                    ion-textarea{
                        --background: var(--ion-color-secondary-contrast);
                        border: 2px solid var(--ion-color-dark-gray);
                        border-radius: 8px;
                        }
            }
        }
        ion-button{
            // padding: 15px;
            margin: 0 auto;
            display: block;
            font-size: 11px;
            font-weight: 500;
            width: 95%;
            border-radius: 10px;
            --background: var(--ion-color-blue);
            color: var(--ion-color-primary-contrast);
            height: 35px;
        }
        .item-inner{
            padding-right: 0px !important;
          }

    }

    app-booked-appointment{
        ion-content {
            --padding-start: 15px !important;
            --padding-end: 15px !important;
        }
        .list-doctors{
            ion-item{
                // border: 1.5px solid var(--ion-color-blue);
                background: #FFFFFF;
                box-shadow: 0px 0px 20px rgb(0 0 0 / 8%);
                border-radius: 8px;
                margin-top: 15px;
                margin-bottom: 10px;
                img{
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                    margin: 0 auto;
                    display: block;
                }
                p{
                    font-size: 11px;
                    color: var(--ion-color-light-contrast);
                    font-weight: 500;
                    span{
                        color: var(--ion-color-primary-tint);
                    }
                }
                ion-icon{
                    background-color: var(--ion-color-danger);
                    color: var(--ion-color-primary-contrast);
                    border-radius: 50%;
                    padding: 5px;
                }
            }
            ion-button{
                // padding: 15px;
                margin: 0 auto 10px;
    display: block;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    --background: #B87ED1;
    height: 40px;
            }
            .remove_inner_bottom{
                --inner-border-width: 0;
            }
            h3{
                font-size: 17px;
                font-weight: 600;
            }
        }
        ion-list{
            ion-item{
                // border-top: 1px dashed #ccc;
                padding-bottom: 10px;
                p{
                    color: var(--ion-color-light-contrast) !important;
                }
            }
        }
        ion-button{
            // padding: 15px;
            margin: 0 auto 10px;
            display: block;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
            border-radius: 10px;
            --background: var(--ion-color-blue);
            color: var(--ion-color-primary-contrast);
            height: 40px;
        }
    }
    app-meeting-details{
        img{
            border-radius: 50%;
            width: 200px;
            height: 200px;
            object-fit: contain;
        }
        .meeting_buttons{
            padding: 25px;
            .video_audio{
                --background-color: var(--ion-color-blue);
                color: var(--ion-color-primary-contrast);
                // padding: 15px;
                font-size: 15px;
                font-weight: 500;
                border-radius: 10px;
                width:100%;
                margin-bottom: 10px;
                height: 38px;
            }
            .join_consultation{
                --background-color: var(--ion-color-secondary);
                color: var(--ion-color-primary-contrast);
                // padding: 15px;
                font-size: 15px;
                font-weight: 500;
                border-radius: 10px;
                width:100%;
                margin-bottom: 10px;
                height: 38px;
            }
            .cancel{
                --background-color: var(--ion-color-secondary-contrast);
                // border: 1px solid var(--ion-color-danger);
                color: var(--ion-color-danger);
                // padding: 15px;
                font-size: 15px;
                font-weight: 500;
                border-radius: 10px;
                width:100%;
                margin-bottom: 10px;
                height: 38px;
            }
            p{
                margin: 0px;
                margin-top: 20px;
                font-size: 12px;
                font-weight: 600;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;
                ion-icon{
                    font-size: 25px;
                }
            }
        }
    }
    app-doctor-feedback{
        .avatar{
        img{
            border-radius: 50%;
            max-width: 250px;
            max-height: 250px;
            object-fit: contain;
        }
    }
        h3{
            font-size: 19px;
            font-weight: 500;
            color: var( --ion-color-blue);
            text-align: center;
            margin: 0px;
        }
        h2{
            font-weight: 600;
            font-size: 30px;
            line-height: 38px;
            text-align: center;
            color: var(--ion-color-warning-contrast);
            margin: 0px;
        }
        p{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: -0.5px;
            margin: 0px;
            margin-top: 10px;
        }
        .ionic5-star-rating {
            margin-top: 30px;
            margin-bottom: 50px;
            ion-button{
                span{
                    font-size: 20px;
                }
            }
        }
        ion-label {
            width: 100%;
            float: left;
            text-align: left;
            font-weight: 500;
            font-size: 19px;
            line-height: 26px;
            letter-spacing: -0.8px;
            color: var(--ion-color-warning-contrast);
            margin-left: 23px;
            margin-right: 20px;
            margin-top: -32px;
        }
        ion-item{
            opacity: 1;
            border-radius: 13px;
            height:112px;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 10px;
        }
        ion-textarea.sc-ion-textarea-md-h.sc-ion-textarea-md-s.md.hydrated {
            --background: var(--ion-color-dark-gray);
            margin: 0 auto;
            border-radius: 10px;
            width: 88%;
          }
          button{
            padding: 15px;
            margin: 20px auto;
            display: block;
            font-size: 16px;
            font-weight: 500;
            width: 80%;
            border-radius: 10px;
            background-color: var(--ion-color-blue);
            color: var(--ion-color-primary-contrast);
          }
    }
    app-upcoming-appointment, app-previous-appointment{
        ion-segment{
            border-bottom: 2px solid #E5E5E5;
        }
        ion-segment-button {
            --color-checked: var( --ion-color-warning-contrast);
            font-weight: 600;
            --indicator-color: transparent;
            text-transform: capitalize;
            min-height: 10px
          }
          .segment-button-checked{
            border-bottom: 2px solid var(--ion-color-primary);
          }
        .appointment_bg {
            // background-color: var(--ion-color-blue);
            // border: 1px solid #6670dd;
            // padding: 10px 7px;
            // margin: 0px;
            // box-shadow: 0px 4px 7px #28a3da47;
            // border-radius: 5px;
            // position: relative;
            padding: 10px 7px;
            margin: 0px;
            box-shadow: 0px 4px 7px #28a3da47;
            border-radius: 5px;
            position: relative;
            background: #fff;
            ion-row{
                align-items: center;
            }
            ion-thumbnail{
                --size: 75px;
                --border-radius: 8px;
                margin: 0 auto;
                display: block;
            }
            // img{
            //     width: 100%;
            //     object-fit: cover;
            //     border-radius: 15px;
            //   }
              .video_icon{
                // display: grid;
                // position: absolute;
                // top: -21px;
                img{
                    width: 25px;
                    height: 18px;
                    margin-bottom: 0px;
                    object-fit: contain;
                    margin-right: 15px;
                }
              }
          .name{
            padding: 0px 0px 0px 0px;
            position: relative;
            p{
                font-weight: 300;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.3px;
                color: #333333;
                margin: 0px;
                text-align: left;
                color: var(--ion-color-warning-contrast);
            }
            h3{
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                letter-spacing: -0.3px;
                color: #333333;
                margin: 0px;
                text-align: left;
            }

          }
          h4{
            position: absolute;
            top: 0px;
            right: 0px;
            background: #6670dd;
            color: #fff;
            font-size: 10px;
            padding: 3px 6px;
            margin: 0;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 3px;
        }
        }
    }
    .user_details{
        width: 100%;
        img{
            // width: 100%;
            // height: auto;
            // max-width: 150px;
            // max-height: 150px;
            // margin: 0 auto;
            // display: block;
            object-fit: contain;
            border-radius: 50%;
            margin-right: 10px;
            // padding: 10px;
            // background-color: #000;
        }
        h2, h3, h5{
            text-align: center;
            margin: 0px;
        }
        h2{
          font-weight: 600;
          font-size: 17px;
          line-height: 33px;
          padding-bottom: 0px;
          color: var(--ion-color-primary-contrast);
          text-align: right;
          white-space: nowrap;
          width: 124px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        h3{
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.3px;
            padding-bottom: 5px;
            color: var( --ion-color-primary-contrast);
        }
        h5{
            font-weight: 200;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.3px;
            padding-bottom: 0px;
            text-align: right;
            color: var(--ion-color-primary-contrast);
        }
    }
    app-edit-profile, app-create-subprofile{
        form{
            padding: 20px;
            ion-label{
                font-weight: 500 !important;
                font-size: 14px !important;
                line-height: 17px !important;
            }
            ion-item{
              border: 2px solid var(--ion-color-gray);
              border-radius: 10px;
              background: #fff !important;
              margin-top: 10px;
              margin-bottom: 10px;
              border-bottom: 1px solid var(--ion-color-gray);
            }
            ion-select{
                border: 2px solid var(--ion-color-gray);
                border-radius: 10px;
                background: #fff !important;
                margin-top: 10px;
            }
            ion-textarea{
                border: 2px solid var(--ion-color-gray);
                border-radius: 10px;
                background: #fff !important;
                margin-top: 10px;
                height:90px;
            }
            .form_fields{
                padding-bottom: 0px;
            }
            .state_city{
                .col-md-6{
                    float: left;
                    width: 50%;
                }
                .col-md-6:first-child{
                    padding-right: 10px;
                }
            }
            // .file-upload{
            //     position: absolute;
            //     right: 15px;
            //     top: 40px;
            //     z-index: 99;
            // }
            // .upload_file{
            //     position: relative;
            // }
            .upload_file>ion-input{
                display:none;
            }
            .select_drpdwn{
                border: 2px solid var(--ion-color-gray);
                border-radius: 10px;
                background: #fff !important;
                margin-top: 10px;
            }
            .fa-user:before {
                content: "\f007";
            }
        }
    }
    app-termsandcondition{
        p{
            padding: 0px 0px;
            text-align: left !important;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.5px;
            word-spacing: 6px;
        }
        .about_field {
            background: linear-gradient(180deg, #F5F7FF 0%, #F5F7FF 100%);
            mix-blend-mode: normal;
            border-radius: 24px 24px 0px 0px;
            padding: 10px 15px;
            margin: 12px;
        }
    }
    .fa-link::before {
        content: "\f0c1";
        font-family: fontawesome;
    }

    app-prescription, app-reports, app-patient-reports, app-account-history{
        ion-segment{
            border-bottom: 2px solid #E5E5E5;
        }
        ion-segment-button {
            --color-checked: var( --ion-color-warning-contrast);
            font-weight: 600;
            --indicator-color: transparent;
            text-transform: capitalize;
            min-height: 10px;
            font-size: 13px;
          }
          .segment-button-checked{
            border-bottom: 2px solid var(--ion-color-primary);
          }
          ion-card{
            background: #FFFFFF;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
            border-radius: 8px;
            .pat_report, ion-card-content{
                display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 25px 20px !important;
                    ion-label{
                        p{
                            font-size: 12px !important;
                            color: var( --ion-color-orange) !important;
                        }
                        h3{
                            font-size: 16px !important;
                            color: var(--ion-color-warning-contrast);
                        }
                        small{
                            font-size: 10px;
                            color: var(--ion-color-light-gray);
                        }
                    }
                    .submit{
                        // background: linear-gradient(254.31deg, #1A6C9A 14.96%, #4E97BF 60.96%) !important;
                        color: var(--ion-color-primary-contrast) !important;
                        // padding: 10px 24px;
                        // border-radius: 10px;
                        font-weight: 500;
                        font-size: 13px;
                        text-decoration: none;
                        text-transform: capitalize;
                        --background: #3972B9;
                        border-radius: 6px;
                        height: 38px;
                        width: 32%;
                    }
                }
             }
        .appointment_bg {
            background-color: var(--ion-color-blue);
            border-radius: 15px;
            position: relative;
            // img{
            //     width: 100%;
            //     height: 100%;
            //     max-width: 160px;
            //     max-height: 160px;
            //     object-fit: contain;
            //     border-radius: 15px;
            //   }
            //   .video_icon{
            //     display: grid;
            //     position: absolute;
            //     top: 15px;
            //     right: 15px;
            //     img{
            //         width: auto;
            //         max-width: 22px;
            //         max-height: 22px;
            //         margin-bottom: 10px;
            //         object-fit: contain;
            //         height: auto;
            //     }
            //   }
          .name{
            padding: 10px 15px 15px 15px;
            p{
                margin: 0px;
                font-size: 12px;
                text-align: left;
                letter-spacing: 0.58px;
                font-weight: 500;
                color: var(--ion-color-primary-contrast);
            }
            h3{
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.58px;
                margin: 0px;
                text-align: left;
                color: var(--ion-color-primary-contrast);
            }
          }
        }
    }




      ion-menu {
        --background: transparent;
        --ion-background-color: transparent;
        --ion-color-base: transparent !important;

        left: 0px;

        ion-toolbar {
          --background: transparent;
        }

        ion-list {
          background: white;
        }

      }

      app-appointment-booking input:checked + .full_week{
        background: var(--ion-color-blue) !important;
      }
    .symptoms {
        display: flex;
        align-items: center;
        margin-left: 0px;
        background: #e5f1ff;
        border-radius: 5px;
        p {
            font-size: 11px;
            line-height: 14px;
            text-align: left;
            letter-spacing: 0.001em;
            color: var(--ion-color-warning-contrast);
            padding-left: 5px;
            white-space: normal;
            font-weight: 600;
            margin: 0px;
        }
        img{
            height: 35px;
            width: 35px;
            object-fit: cover;
            border-radius: 6px;
            background: #fff;
            margin: 6px 0px 6px 3px;
            box-shadow: 0px 0px 3px #d3caca;
        }
    }

    .alert-radio-group.sc-ion-alert-md, .alert-checkbox-group.sc-ion-alert-md {
        position: relative;
        border-top: 0px solid var(--ion-color-step-150, #d9d9d9);
        border-bottom: 0px solid var(--ion-color-step-150, #d9d9d9);
        overflow: auto;
      }
      .alert-button-group.sc-ion-alert-md button{
        bottom: 0px !important;
      }

      .caledr_icon{
        position: absolute;
        right: 2px;
        background-color: #fff;
      }

    //   Blog page css

    .blog_page{
        display: flex;
        align-items: center;
        .col-md-4{
            width: 40%;
            float: left;
            img{
                border-radius: 10px;
            }
        }
        .col-md-8{
            width: 60%;
            float: left;
        }
        h2{
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            margin: 0;
            margin-left: 10px;
        }
    }

    app-change-password {
        form {
            padding: 15px 15px;
            ion-item {
                width: 100%;
                border: 2px solid rgba(27, 27, 27, 0.15);
                border-radius: 8px;
                float: left;
                background-color: #fff !important;
                margin-top: 10px;
                margin-bottom: 5px;
                outline: none;
            }
            ion-label{
                float: left;
                width: 100%;
                text-align: left;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }
            .show-option, .hide-option{
                color: #000;
                font-size: 20px;
                padding-top: 5px;
            }
        }
        .submit{
            width: 100%;
            margin: 0 auto;
            display: block;
            --background: var(--ion-color-dark-blue) !important;
            color: var(--ion-color-primary-contrast) !important;
            --border-radius: 8px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            height:50px;

        }
    }

    app-forgot-password{
        form {
            padding: 15px 15px;
            ion-item {
                width: 100%;
                border: 2px solid rgba(27, 27, 27, 0.15);
                border-radius: 8px;
                float: left;
                background-color: #fff !important;
                margin-top: 10px;
                margin-bottom: 20px;
                outline: none;
                border-bottom: 1px solid rgba(27, 27, 27, 0.15);
            }
            ion-label{
                float: left;
                width: 100%;
                text-align: left;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }
            .submit{
                width: 100%;
                --background: var(--ion-color-dark-blue) !important;
                color: var(--ion-color-primary-contrast) !important;
                --border-radius: 8px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                height:50px;

            }
            .send_back_icns{
                display: flex;
                align-items: center;
                clear: both;
            }
            ion-icon{
                width: 10%;
                background: var(--ion-color-blue) !important;
                color: var(--ion-color-primary-contrast) !important;
                border-radius: 8px;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                height: 29px;
                padding: 10px;
            }
        }
        h1{
            font-weight: 400;
            font-size: 31px;
            line-height: 37px;
            margin-top: 0px;
        }
        p{
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          margin: 10px;
        }
    }
    .forget_text{
        float: right;
        color: var(--ion-color-blue);
        text-decoration: none;
        font-weight: 500;
        margin-top: 0px;
    }
    .notifi_content{
        .notify{
            display: flex;
            align-items: center;
        }
        .col-md-3{
            border-radius: 50%;
            margin-right: 12px;
            padding: 20px;
            .notif_icon{
                margin: 0 auto;
                display: block;
                font-size: 35px;
                color: #fff;
                align-items: center;
                text-align: center;
            }
        }
        .notfy_img{
            background: linear-gradient(180deg, #FF5D7B 0%, #E14360 100%);
        }
        .notfy_img1{
            background: linear-gradient(180deg, #387CFE 0%, #689CFF 100%);
        }
        .notfy_img2{
            background: linear-gradient(180deg, #FFCE4F 0%, #EAB325 100%);
        }
        // .col-md-9{
        //     width:70%;
        //     float: left;
        //     padding-left: 10px;
        // }
        h4{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: var(--ion-color-black_light);
            margin-bottom: 5px;
            margin-top: 0px;
        }
        p{
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: var(--ion-color-black_light);
        }

    }

    .card-content-md {
        padding: 0px !important;
    }
    .card_notify{
        padding: 14px 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
    }
    h3{
        font-weight: 500;
        font-size: 16px;
        line-height: 27px;
        margin-left: 10px;
    }
    app-about-us{
        p{
            padding: 0px 0px;
            text-align: left !important;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.5px;
            word-spacing: 6px;
        }
        .about_field {
            background: linear-gradient(180deg, #F5F7FF 0%, #F5F7FF 100%);
            mix-blend-mode: normal;
            border-radius: 24px 24px 0px 0px;
            padding: 10px 15px;
            margin: 12px;
        }
    }


    // media query search 5/9/2022

    @media (max-width:450px){


    .searchbar-clear-button.sc-ion-searchbar-md {
        right: 5px;
        top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        position: absolute;
        height: 100%;
        border: 0;
        background-color: transparent;
    }

    .searchbar-clear-icon.sc-ion-searchbar-md {
        // background-color: #F5F5F5 !important;
        background-color: none !important;
        width: 18px;
        height: 100%;
    }

}

@media (min-width:450px) and (max-width:600px){
    .searchbar-clear-button.sc-ion-searchbar-md {
        right: 65px;
        top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        position: absolute;
        height: 100%;
        border: 0;
        background-color: transparent;
    }

    .searchbar-clear-icon.sc-ion-searchbar-md {
        // background-color: #F5F5F5 !important;
        background-color: #fff !important;
        width: 22px;
        height: 100%;
        padding: 0px 10px !important;
    }

}

@media (min-width:600px) and (max-width:768px){
    .searchbar-clear-button.sc-ion-searchbar-md {
        right: 80px;
        top: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        position: absolute;
        height: 100%;
        border: 0;
        background-color: transparent;
    }

    .searchbar-clear-icon.sc-ion-searchbar-md {
        // background-color: #F5F5F5 !important;
        background-color: none !important;
        width: 22px;
        height: 100%;
    }

}

.con-fir {
    width: 100%;
    height: 30px;
}

// Dashboard specialities 10-09-2022 SA

.sp-box {
    /* box-shadow: 0px 17px 70px -11px rgb(0 0 0 / 10%); */
    box-shadow: 0px 0px 6px #cacaca9c;
    padding: 5px 5px;
    // margin: 0px 10px;
    border-radius: 16px;
    /* background: white !important; */
    border: none !important;
    width: 80px !important;
    height: 80px !important;
}

    // .sp-box img {
    //     width: 60px !important;
    //     height: 60px !important;
    // }

    .rem-svg {
        color: #fff;
        background: #DC4632;
        border-radius: 5px;
        font-size: 20px;
        font-weight: 700;
        position: absolute;
        top: -10px;
        right: 5px;
    }

    .detail-doct h2 {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        margin: 0px 0px 3px 0px !important;
    }

    .detail-doct h5{
        margin: 0px !important;
        color: #333333;
        font-size: 14px;
        font-weight: 300;

    }

    .detail-doct2 h2 {
        font-size: 22px;
        font-weight: 500;
        color: #3971B6;
        margin: 0px 0px 0px 0px !important;
        line-height: 28px;
    }

    .detail-doct2 h6 {
        margin: 0px !important;
        color: #333 !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    .hosp-detail h6 {
        margin: 0px !important;
        color: #424242;
        font-size: 15px;
        font-weight: 600;

    }

    .hosp-detail h2 {
        margin: 0px 0px 8px 0px !important;
        font-size: 17px;
        font-weight: 600;
    }

    .hos-logo {
        text-align: right;
    }

    .doc-pro {
        text-align: right;
    }

    .doc-pro img {
        max-width: 150px;
        /* height: 111px; */
        width: 100%;
        text-align: right;
        height: 100%;
        border-radius:8px;
    }

    .detail-doct {
        margin-bottom: 5px;
        padding-left: 10px;
    }
    .detail-doct2 {
        margin-bottom: 0px;
        padding-left: 10px;
    }


    .part-card {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgb(0 0 0 / 8%);
        border-radius: 8px;
        padding: 8px 10px;
        margin-bottom: 20px;
        ion-row{
            align-items: center;
        }
    }

    .doc-lan, .about_me {
        border: 1px solid #8181814f;
        border-radius: 19px;
        text-align: center;
        margin-top:40px;
        position: relative;
        p{
            font-size: 13px;
            font-size: 500;
        }
        h4 {
            color: #000 !important;
            font-size: 16px;
            margin-top: 35px;
            font-weight: 600;
        }
    }

    .doc-educ h3 {
        text-align: left;
        font-size: 20px;
        font-weight: 400;
        margin-left:0px;
    }
    .doc-educ p {
        font-size: 15px;
        font-weight: 500;
        color: #000;
    }
    .doc-educ ion-button {
        --background: #3971B6;
        color: #fff;
        width: 90%;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin: 10px auto;
        height: 40px;
        display: block;
        border-radius: 3px;
    }

    .main-doc-detail {
        margin-bottom: 10px;
        padding-top:10px;
    }

    .pro-file img {
        border-radius: 8px;
        width: 90px;
        height: 90px;
        object-fit: cover;
    }

    .educ-bott {
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .hos-logo img {
        width: 100%;
        max-width: 100px;
    }

    .hos-bx {
        background: linear-gradient(107.14deg, #f9eefc 42.34%, rgba(229, 210, 236, 0) 69.2%);
        border-radius: 16px;
    }

    .con-sulticon svg{
        width: 25px !important;
        height: 25px;
        align-items: center;
        vertical-align: middle;
    }

    .d-lan {
        font-size: 13px !important;
        font-weight: 400 !important;
        color: #424242 !important;
        white-space: nowrap;
        width: 150px;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
    }

    .m-bot {
        margin-bottom: 40px;
        margin-top: 10px;
    }
    .health_blogs{
        --padding-start: 15px;
        --padding-end: 15px;
        p{
            font-size: 14px;
        }
    }

  //  play icon
  .par-ent {
    position: relative;
  }
    .chld-im .play_icon {
      width: 100%;
      max-width: 70px;
      height: 100px;
  }

  .chld-im {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 41%;
    left: 50% !important;
  }

  .nointernet {
    // --width: 450px;
    --max-width: 100% !important;
    --max-height: 100% !important;
    --height: 100% !important;
  }
  .nointernet .alert-message.sc-ion-alert-md {
    max-height: 100%;
    font-size: 16px;
  }

  .nointernet img{
    margin-top: 100px;

  }
  .nointernet p{
   text-align: center;

  }

  .sub_user h3 {
    margin: 0px !important;
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

.search-symm {
    padding: 10px 15px !important;
}

.wallet_coin{
    margin-left: 15px;
    margin-right: 10px;
  ion-row{
    align-items: center;
  }
}

// wallet css
.wallet_coupon{
    padding: 10px;
    margin-bottom: 20px;
    h3{
        margin: 0px 0px 5px;
        font-size: 21px;
        width: 100%;
        color: var(--ion-color-warning-contrast);
        font-weight: 600;
        text-transform: capitalize;
    }
    p{
        margin: 0px;
        width: 100%;
        color: var(--ion-color-warning-contrast);
    }
}
app-wallet{
    ion-segment{
        border-bottom: 2px solid #E5E5E5;
        ion-segment-button{
            // --color-checked: var( --ion-color-warning-contrast);
            font-weight: 600;
            // --indicator-color: transparent;
            text-transform: capitalize;
            min-height: 10px;
        }
    }
}
app-book-lab{
dp-date-picker{
input[type="text"] {
    border: none;
    outline: none;
    height: 41px;
    margin-left: 10px;
    width: 95%;
 }
}
}
app-blogs, app-health-tips{
    ion-segment{
        border-bottom: 2px solid #E5E5E5 !important;
    }
}

.terms_txts{
    b{
        text-decoration: underline;
    }
}

.red {
    background-color: #fff;
    color: #fff;
}

input.searchbar-input.sc-ion-searchbar-md {
    font-size: 16px !important;
}
.action-sheet-group.sc-ion-action-sheet-md:first-child{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.message.my-message{
  span{
    p{
    padding-top: 5px;
    white-space: normal !important;
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    text-align: left !important;
    margin: 0px !important;
    }
  }
}
